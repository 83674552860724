import { createSlice } from '@reduxjs/toolkit'

export const segmentSlice = createSlice({
    name: 'segmentClick',
    initialState: {
        value: null,
    },
    reducers: {
        segmentClickAction: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { segmentClickAction } = segmentSlice.actions

export default segmentSlice.reducer