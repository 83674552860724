import React from 'react'
import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"
import { connect } from 'react-redux'
//import { filterAction, presetAction, tableClickAction, segmentClickAction, tabIndexAction, locusAction, CNVGlobalAction, backendAction } from '../../actions/action'
import './CSS.css' //fix to crazy virtualized ReactTable scrollbar behavior
// import ExportReactXLS from './ExportReactXLS' // table download
import { Row, Col, Button } from 'react-bootstrap'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import cloneDeep from 'lodash/cloneDeep'
import cytobands from './cytobands.json'
import { chrClickAction } from './Slices/chrSlice'
import { tableClickAction } from './Slices/tableSlice'


class SearchTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filtered: [], // current filters, exported to globalFilters
            hoverRow: null,
            hoverCol: null,
            scrollToRow: null,
            segmentColor: false,
            columnFiltersDiv: false,
            sampleNotesToggle: false,
            sampleInfoToggle: false,
            table: null,
            export: null,
            cytobands: cytobands
        }
    }

    onFilteredChangeCustom = (value, accessor) => {
        var preset = this.props.preset ? this.props.preset : 'No preset'
        this.props.filterAction({ [preset]: { [this.props.table]: { filters: [{ id: accessor, value: value }] } } })
    }


    CNVclick = (e) => {
        tableClickAction(e)
        chrClickAction(e)
    }

    segmentScroll = () => {
        if (this.props.segmentClick !== null) {
            var index = Object(this.state.table.getResolvedState().sortedData).map(x => x._original).findIndex(x =>
                x.chromosome === this.props.segmentClick.chromosome &
                x.start.replace(/,/g, '') === this.props.segmentClick.start &
                x.end.replace(/,/g, '') === this.props.segmentClick.end
            )

            // index in unfiltered table for highlight purposes
            var orig_index = this.state.table.props.data.findIndex(x =>
                x.chromosome === this.props.segmentClick.chromosome &
                x.start.replace(/,/g, '') === this.props.segmentClick.start &
                x.end.replace(/,/g, '') === this.props.segmentClick.end
            )

            if (index === -1) return null

            if (this.state.table.state.page !== Math.floor(index / this.state.table.state.pageSize)) {
                //Super-duper hacky, I know you cannot mutate the state, but everything else fails...
                var tmp_table = Object.assign({}, this.state.table) //probably does not deep clone the state
                tmp_table.state.page = Math.floor(index / this.state.table.state.pageSize)
                this.setState({ table: tmp_table })
            }

            try {
                setTimeout(() => {
                    $('.ReactTable_' + this.props.table)[0].scrollIntoView({ behavior: "smooth" }) //scroll to the table
                }, 1)
                setTimeout(() => {
                    $('.ReactTable_' + this.props.table + ' .rt-table .rt-tbody')[0].children[index - (Math.floor(index / this.state.table.state.pageSize) * this.state.table.state.pageSize)].scrollIntoView({ behavior: "smooth", block: "center" })
                }, 10)
            } catch (e) {
                console.log(e)
            }

            this.setState({ segmentColor: orig_index })
            this.props.segmentClickAction(null)
        }
    }


    checkChange = () => {
        this.props.filterAction({ [this.props.preset]: { [this.props.table]: { columns: Object.values(this.refs).filter(x => x.checked === true).map(x => x.name) } } })
    }
    growColumnFiltersDiv = () => {
        this.setState({ columnFiltersDiv: !this.state.columnFiltersDiv })
    }

    sampleNotesToggle = () => {
        this.setState({ sampleNotesToggle: !this.state.sampleNotesToggle });
    }
    sampleInfoToggle = () => {
        this.setState({ sampleInfoToggle: !this.state.sampleInfoToggle });
    }

    updateTableReference = (r) => {
        if (this.state.table !== r & r !== null) {
            this.setState({ table: r })
        }
    }


    exportToCSV = (exportCols) => {
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        var tableData = cloneDeep(this.state.table.getResolvedState().sortedData)
            .map(x => exportCols
                .map(y => [{ [y.Header]: x[y.Header] }][0])
                .reduce(function (acc, val) {
                    return Object.assign(acc, val);
                }, {}))
        // tableData.forEach(x => delete x.IGV)
        tableData.forEach(x => delete x.IMGT)
        tableData.forEach(x => exportCols.forEach(y => x[y] = isNaN(Number(x[y])) | isNaN(parseFloat(x[y])) ? x[y] : Number(x[y])))
        const ws = XLSX.utils.json_to_sheet(tableData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'table.xlsx');
        // this.tableSave()
    };


    render() {
        if (!this.props.data) {
            return (<div>No data</div>)
        } else {
            var pre_cols = this.props.data ? Object.keys(this.props.data[0]) : null

            // filtering table columns
            var allColumns = this.props.data ? [...pre_cols] : null
            if (this.props.table === 'CNV') {
                allColumns.splice(4, 0, 'length')
                allColumns.splice(5, 0, 'cyt_start')
                allColumns.splice(6, 0, 'cyt_end')
            }

            var data = this.props.data ? JSON.parse(JSON.stringify(this.props.data)) : [{ a: 'No data' }]
            this.data = this.props.data

            var filterColumns = null

            function getTextWidth(text, font) {
                // re-use canvas object for better performance
                const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
                const context = canvas.getContext("2d");
                context.font = font;
                const metrics = context.measureText(text);
                return metrics.width;
            }

            function width(data, col) {
                try { //not really needed, but sometimes old results have 'comment' instead of 'commentS' and stuff breaks
                    if (col == 'gene') {
                        var width = 180
                    } else {
                        var width = Math.max(100, data.map(y => getTextWidth(y[col].toString()) * 1.7 + 5), getTextWidth(col.toString()) * 1.7 + 25)
                    }
                    return width
                } catch (e) {
                    return (100)
                }
            }

            var columns = this.props.data ?
                pre_cols.map(x => [{
                    Header: x,
                    accessor: (d) => ['log2'].includes(x) ? Number(d[x]) : d[x],
                    id: x,
                    minWidth: width(this.props.data, x),
                    maxWidth: x !== 'sequence context' ? 300 : width(this.props.data, x),
                    sortMethod: (a, b) => {
                        if (['start', 'end', 'depth', 'probes', 'var num', 'weight', 'Start_Position', 'tumor_DP', 'tumor_AD_ref', 'tumor_AD_alt'].includes(x)) { //length col filtering below in 'CNV'
                            return Number(a.replace(/,/g, '')) > Number(b.replace(/,/g, '')) ? 1 : -1
                        } else if (['chromosome', 'Chromosome'].includes(x)) {
                            return 0
                        }
                        a = a === null || a === undefined ? -Infinity : a
                        b = b === null || b === undefined ? -Infinity : b
                        a = typeof a === "string" ? a.toLowerCase() : a
                        b = typeof b === "string" ? b.toLowerCase() : b
                        if (a > b) { return 1 }
                        if (a < b) { return -1 }
                        return 0;
                    }

                }][0]) :
                [{ Header: "No data", accessor: 'a' }]

            if (this.props.table === 'CNV') { // adding length column here, before column filters, so that it can be seen and filtered in the column filter list
                columns.splice(4, 0, { Header: 'length', accessor: (d) => d['length'], id: 'length', Cell: null, sortMethod: (a, b) => { return Number(a.replace(/,/g, '')) > Number(b.replace(/,/g, '')) ? 1 : -1 } })
                columns.splice(4, 0, { Header: 'cyt_start', accessor: (d) => d['cyt_start'], id: 'cyt_start', Cell: null })
                columns.splice(5, 0, { Header: 'cyt_end', accessor: (d) => d['cyt_end'], id: 'cyt_end', Cell: null })
            }


            // if (this.props.table === 'CNV') {
            //     data.forEach((x) => {
            //         x.length = (x.end + ''.replace(/,/g, '') - x.start + ''.replace(/,/g, '')).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            //         x.start = x.start.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            //         x.end = x.end.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            //         x.cyt_start = this.state.cytobands.filter(y => y.CHR === (x.chromosome.startsWith('chr') ? x.chromosome.slice(3) : x.chromosome) & Number(y.from) <= Number(x.start.replace(/,/g, '')) & Number(y.to) > Number(x.start.replace(/,/g, '')))[0].arm
            //         x.cyt_end = this.state.cytobands.filter(y => y.CHR === (x.chromosome.startsWith('chr') ? x.chromosome.slice(3) : x.chromosome) & Number(y.from) <= Number(x.end.replace(/,/g, '')) & Number(y.to) > Number(x.end.replace(/,/g, '')))[0].arm
            //     })
            // }

            if (filterColumns !== undefined & filterColumns !== null) {
                columns = columns.filter((x) => filterColumns.includes(x.Header))
            }

            var colsWithBlanks = ["af", "eur_af", "gnomad_af", "gnomad_nfe_af", "max_af"];
            var numFilter = function (filter, row) {
                if (row[filter.id] && row[filter.id] !== "") {
                    if (filter.value.includes(";") && (filter.value.split(";")[1] !== "" && filter.value.split(";")[1] !== ">" && filter.value.split(";")[1] !== "<")) {
                        var filter1 = filter.value.split(";")[0]
                        var filter2 = filter.value.split(";")[1]
                        var filterLow = filter1.includes("<") ? Number(filter1.split("<")[1]) : Number(filter2.split("<")[1])
                        var filterHigh = filter2.includes(">") ? Number(filter2.split(">")[1]) : Number(filter1.split(">")[1])

                        if (row[filter.id] < filterLow && row[filter.id] > filterHigh) {
                            return (true)
                        } else {
                            return (false)
                        }
                    } else if (filter.value.includes("<")) {
                        if (row[filter.id] < Number(filter.value.split("<")[1])) {
                            return (true)
                        } else {
                            return (false)
                        }
                    } else if (filter.value.includes(">")) {
                        if (row[filter.id] > Number(filter.value.split(">")[1])) {
                            return (true)
                        } else {
                            return (false)
                        }
                    } else if (filter.value.includes("=")) {
                        if (Number(row[filter.id]) === Number(filter.value.split("=")[1])) {
                            return (true)
                        } else {
                            return (false)
                        }
                    } else {
                        return (true)
                    }
                } else {
                    return (true)
                }
            }
            var charFilter = function (filter, row) {
                if (row[filter.id]) {
                    if (filter.value === " ") {
                        return (row[filter.id] === null)
                    } else {
                        return (row[filter.id].toLowerCase().match("^" + filter.value.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1").toLowerCase()) !== null)
                        // return (row[filter.id].toLowerCase().includes(filter.value.toLowerCase()))
                    }
                } else {
                    return (null)
                }
                // Honestly I have no idea why I have to check if row[filter.id] exsists in the line above. Without the check, there's a crash saying that it's empty if you filter column 5 in first SNV table...
                // The column is almost the same as the others and I cannot even put a breakpoint before when debugging. Anyway, works now.
            }
            var arrFilter = function (filter, row) {
                if (filter.value === null || filter.value.length === 0) {
                    return (true)
                } else if (filter.value.length > 0) {
                    return (filter.value.map(x => x.toString().toLowerCase()).includes(row[filter.id].toString().toLowerCase()))
                } else {
                    return (null)
                }
            }
            var varFilter = function (filter, row) {
                if (row[filter.id] && filter.value !== null && filter.value !== "") {
                    if (filter.value === null || filter.value.constructor === Array) {
                        return (arrFilter(filter, row))
                    } else if (typeof row[filter.id] === "number" || !isNaN(Number(row[filter.id]))) {
                        return (numFilter(filter, row))
                    } else {
                        return (charFilter(filter, row))
                    }
                } else {
                    if (filter.value === '' || filter.value === ' ' || filter.value === null || filter.value[0] === '') {
                        return (true)
                    } else if (colsWithBlanks.includes(filter.id) && row[filter.id] === "") {
                        return (true)
                    } else {
                        return (null)
                    }
                }
            }

            //set column specific filters based on data type
            for (var i = 0; i < columns.length; i++) {
                columns[i].filterMethod = (filter, row) => varFilter(filter, row)
            }

            // natural sorting helper
            const comparator = (a, b) => {
                return a.toString().localeCompare(b.toString(), 'en', { numeric: true })
            };
            // add filtering by options
            var categColums = ["Chromosome", "Variant_Classification",
                "rearrangement class"];
            columns.filter(x => categColums.includes(x.id)).forEach(x => {
                x.Filter = ({ filter, onChange }) =>
                    <Select
                        onChange={event => onChange(event !== null ? event.map(x => x.value) : event)}
                        value={filter ? [filter].map(x => x.value.length !== 0 ? x : null).filter(x => x !== null).forEach(x => x.label = x.value) : null}
                        closeMenuOnSelect={false}
                        defaultValue={null}
                        isMulti
                        options={[...new Set(this.props.data.map(y => y[x.id]))].sort(comparator).map(y => [{ value: y, label: y }][0])}
                        className="basic-multi-select filterByOptions"
                        classNamePrefix="select"
                    />
            })
            var filters = null

            var columnFiltersDiv_grow = this.state.columnFiltersDiv ? { label: <div><FontAwesomeIcon icon={faAngleUp} /> Hide column filtering</div>, height: $('#columnFiltersDiv_height')[0].clientHeight } : { label: <div><FontAwesomeIcon icon={faAngleDown} /> Show column filtering</div>, height: "0px" };
            var columnFiltersDiv = null;
            var columnFiltersBtn = null;
            if (this.props.table !== 'Summary') {
                columnFiltersDiv =
                    <div id="columnFiltersDiv_grow" style={{ height: columnFiltersDiv_grow.height }}>
                        <div id="columnFiltersDiv_height" className="columnFiltersDiv_content">
                            <ol>
                                {allColumns.map((x) => {
                                    return (
                                        <li key={x}>
                                            <input type="checkbox" name={x} ref={x} onChange={this.checkChange} checked={allColumns.includes(x)} />
                                            <label htmlFor={x} style={{ 'paddingLeft': '5px' }}>{x}</label>
                                            <br></br>
                                        </li>
                                    )
                                }, this)}
                            </ol>
                        </div>
                    </div>;
                columnFiltersBtn = <Button variant="info" size="sm" className="fillAvailableBtns" onClick={this.growColumnFiltersDiv} > {columnFiltersDiv_grow.label} </Button>;
            }


            if (![null, undefined].includes(this.props.segmentClick)) {
                this.segmentScroll()
            }

            if (filters == null) { filters = [] }

            var tableHeight = 500;
            if ($(window).height() && ($(window).height() - 290 > 500)) {
                tableHeight = $(window).height() - 290;
            }


            return (
                <div>
                    {/* <Row >
                        <Col xs={12}>
                            {columnFiltersDiv}
                        </Col>
                    </Row > */}
                    <Row className="tabButtonsRow">
                        {/* <Col xs={2}>
                            {columnFiltersBtn}
                        </Col> */}
                        <Col xs={5}>
                        </Col>
                        <Col xs={3}>
                            <Row >
                                <Col xs={5}>
                                    <button className="exportReactCSVlink fillAvailableBtns btn btn-secondary btn-sm" onClick={(e) => this.exportToCSV(columns)}>Export</button>
                                </Col>
                            </Row >
                        </Col>
                    </Row >
                    <ReactTable
                        ref={r => { this.updateTableReference(r) }}
                        data={data}
                        columns={columns}
                        className={"-striped -highlight ReactTable_" + this.props.table}
                        style={{ height: tableHeight }}
                        // showPagination={true}
                        filterable
                        onFilteredChange={(filtered, column, value) => {
                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                        }}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}

                        defaultFiltered={filters ? filters : []}
                        defaultPageSize={50}
                        getTdProps={(state, rowInfo, column, instance) => {
                            if (typeof rowInfo !== "undefined" & this.props.table === 'CNV') {
                                if (this.props.segmentClick !== null) {
                                    //this.segmentScroll()
                                }

                                if (rowInfo.row.loh === '' | rowInfo.row.loh === undefined) {
                                    if (this.state.segmentColor !== false) {
                                        console.log()
                                    }
                                    return {
                                        style: {
                                            fontWeight: 400,
                                            background: this.state.segmentColor === rowInfo.index ? 'yellow' : '#E1E5E8',
                                            cursor: 'pointer'
                                        },
                                        onClick: () => {
                                            this.CNVclick(rowInfo)
                                            this.setState({ segmentColor: rowInfo.index })
                                            $('.vaf')[0].scrollIntoView({ behavior: "smooth", block: "start" })
                                        },
                                        onMouseEnter: () => {
                                            this.setState({ hoverRow: rowInfo.index, hoverCol: column.Header })
                                        },
                                        onMouseLeave: () => {
                                            this.setState({ hoverRow: null, hoverCol: null })
                                        }
                                    }
                                } else {
                                    return {
                                        style: {
                                            fontWeight: 200,
                                            background: this.state.segmentColor === rowInfo.index ? 'yellow' : 'white',
                                            cursor: 'pointer'
                                        },
                                        onClick: () => {
                                            this.CNVclick(rowInfo)
                                            this.setState({ segmentColor: rowInfo.index })
                                            $('.vaf')[0].scrollIntoView({ behavior: "smooth", block: "start" })
                                        },
                                        onMouseEnter: () => {
                                            this.setState({ hoverRow: rowInfo.index, hoverCol: column.Header })
                                        },
                                        onMouseLeave: () => {
                                            this.setState({ hoverRow: null, hoverCol: null })
                                        }
                                    }
                                }
                            }
                            return {} // little children die if this return is gone
                        }}
                    />
                    <br />
                </div>
            )
        }
    }
}

export default SearchTable
