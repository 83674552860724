import { configureStore, createSlice } from '@reduxjs/toolkit'
import chrReducer from './Slices/chrSlice'
import tableReducer from './Slices/tableSlice'
import segmentReducer from './Slices/segmentSlice'

export default configureStore({
  reducer: {
    chr: chrReducer,
    table: tableReducer,
    segment: segmentReducer

  },
});

