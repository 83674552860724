import { createSlice } from '@reduxjs/toolkit'

export const chrSlice = createSlice({
    name: 'chrClick',
    initialState: {
        value: null,
    },
    reducers: {
        chrClickAction: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { chrClickAction } = chrSlice.actions

export default chrSlice.reducer