import './App.css'
import Plotting from './LymphoCNV.jsx'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import cyt from '../cytoBand_hg38.json'
import { usePapaParse } from 'react-papaparse'
import SearchTable from './SearchTable'

function App() {
  var cytobands = cyt
  const [CNRFile, setCNRFile] = useState()
  const [CNR, setCNR] = useState()
  const [SegFile, setSegFile] = useState()
  const [Seg, setSeg] = useState()
  const [VarFile, setVarFile] = useState()
  const [Var, setVar] = useState()

  const segment = useSelector((state) => state.segment.value)

  const { readString } = usePapaParse()

  const fileReader = new FileReader()

  const changeCNR = (e) => {
    setCNRFile(e.target.files[0], () => {
    })
  }

  const changeSeg = (e) => {
    setSegFile(e.target.files[0], () => {
    })
  }

  const changeVar = (e) => {
    setVarFile(e.target.files[0], () => {
    })
  }

  useEffect(() => {
    if (CNRFile) {
      fileReader.onload = function (event) {
        var textInput = event.target.result
        var parsedText = readString(textInput, { header: true }).data

        setCNR(parsedText)
      }

      fileReader.readAsText(CNRFile)
    }
  }, [CNRFile])

  useEffect(() => {
    if (SegFile) {
      fileReader.onload = function (event) {
        var textInput = event.target.result
        var parsedText = readString(textInput, { header: true, delimiter: '\t' }).data
        parsedText.filter(x => x.chromosome != '') // check if there is no empty line

        setSeg(parsedText)
      }

      fileReader.readAsText(SegFile)
    }
  }, [SegFile])

  useEffect(() => {
    if (VarFile) {
      fileReader.onload = function (event) {
        var textInput = event.target.result

        var samples = readString(textInput, { header: true, delimiter: "\t", newline: "\n", comments: "##" }).meta.fields
        samples.splice(0, 9)

        var parsedText = readString(textInput, { header: true, delimiter: "\t", newline: "\n", comments: "##" })
          .data
          .filter(x => Object.keys(x).length > 1)
          .map(x => [{
            chromosome: x['#CHROM'],
            start: Number(x.POS),
            end: Number(x.POS) + x.ALT.length,
            alt_freq: Number(x.LGL_003.split(':')[1].split(',')[1]) / Number(x.LGL_003.split(':')[2]),
            depth: Number(x.LGL_003.split(':')[2])
          }][0])
        setVar(parsedText)
      }

      fileReader.readAsText(VarFile)
    }
  }, [VarFile])

  return (
    <div className="App">
      Upload CNV data points <input type="file" onChange={changeCNR} />
      Upload CNV segments <input type="file" onChange={changeSeg} />
      Upload Variants <input type="file" onChange={changeVar} />
      <Plotting data={[cytobands, CNR, Seg, Var]} sample='wat' />
      <SearchTable
        data={Seg}
        table={'CNV'}
        trigger={segment}
        // scroll={this.plotRef}
      />
    </div>
  )
}

export default App
