import React, { useState, useEffect, useRef } from 'react'
import { Plotting } from './plotting'
import { useSelector, useDispatch } from 'react-redux'
import { chrClickAction } from './Slices/chrSlice'
import { tableClickAction } from './Slices/tableSlice'
import { segmentClickAction } from './Slices/segmentSlice'

function LymphoCNV(props) {

    const [plot, setPlot] = useState({})
    const [loading, setLoading] = useState('Loading CNV...')

    const chr = useSelector((state) => state.chr.value)
    const dispatch = useDispatch()
    const prevChrRef = useRef()

    useEffect(() => {
        //creating the plot object is pretty heavy
        //the setTimout function should make the call asynchronous
        //so it doesn't block the rest of the react app

        if (Object.keys(plot).length !== 0) {
            setLoading(true)
            plot.destroy() // ?
            setTimeout(() => {
                plot.destroy() // ?
                setPlot(new Plotting([...props.data, segmentClick, chrClick, chr]))
                setLoading(false)
            }, 1)
        } else {
            setPlot(new Plotting([...props.data, segmentClick, chrClick, chr]))
            setLoading(false)
        }

        // setSample(props.sample)

        // if (sample !== props.sample || tableClick !== props.tableClick) {
        //     if (tableClick !== props.tableClick) {
        //         plot.update(props.tableClick)
        //         // props.CNVGlobalAction({ row: props.tableClick.row })
        //         setLoading(false)
        //     } else if (Object.keys(plot).length !== 0) {
        //         setLoading(true)
        //         plot.destroy()
        //         setTimeout(() => {
        //             plot = new Plotting([...props.data, this.segmentClick, this.chrClick])
        //             setLoading(false)
        //         }, 1)
        //     }
        //     setSample(props.sample)
        //     setTableClick(props.tableClick)
        // }
        //return () => props.tableClickAction(null) //return is componentWillUnmount
    }, [props]) //, [] is componentDidMount 


    useEffect(() => {
        if (chr !== null & JSON.stringify(chr) !== JSON.stringify(prevChrRef.current)) {
            plot.destroy()
            setTimeout(() => {
                setPlot(new Plotting([...props.data, segmentClick, chrClick, chr]))
                setLoading(false)
                prevChrRef.current = chr
            }, 1)
        }
    }, [])

    const segmentClick = (e) => {
        dispatch(segmentClickAction(e))
    }

    const chrClick = (e) => { // set chr
        if (Object.keys(e)[0] === 'chromosome') {
            dispatch(chrClickAction({ row: e }))
        } else {
            dispatch(chrClickAction({ row: { chromosome: '6', start: e.pos[0].toString(), end: e.pos[1].toString() } }))
        }
    }

    return (
        <div id="vis">
            {loading ? <h4>Loading CNV...</h4> : ""}
            <div className="view-port">
            </div>
        </div>
    )

}
export default LymphoCNV